import React from "react";

function ServiceArea({ className }) {
  return (
    <section className={className || ""}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title text-center mb-50 tg-heading-subheading animation-style3">
              {/* <span className="sub-title tg-element-title">What We Do</span> */}
              <h2 className="title tg-element-title">Our Projects</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center gap-4">
          <div className="col-lg-4 col-md-6 col-sm-10 ">
            <div
              className="services-item wow fadeInUp"
              data-wow-delay=".2s"
              style={{
                background: `url("../../assets/img/services/services_item01.jpg")`,
              }}
            >
              <div className="services-icon">
                <img
                  src={
                    require(`../../assets/img/icon/Home_Icon.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="services-content">
                <h2 className="title">
                  <a >The Palm Paradise</a>
                </h2>
                <h2 className="number">01</h2>
              </div>
              <div className="services-overlay-content">
                <h2 className="title">
                  <a >The Palm Paradise</a>
                </h2>
                <p>
                  Our flagship residential project, The Palm Paradise, offers luxurious
                  3BHK homes designed to provide unparalleled comfort and convenience.
                  With meticulous attention to detail and a focus on modern amenities,
                  The Palm Paradise is a testament to our commitment to excellence in
                  residential living.
                </p>
                <a href="/The_Palm_Paradise.pdf" download className="read-more" >
                  Download Brochure <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>&nbsp;
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div
              className="services-item wow fadeInUp"
              data-wow-delay=".4s"
              style={{
                background: `url("../../assets/img/services/services_item02.jpg")`,
              }}
            >
              <div className="services-icon">
                <img
                  src={
                    require(`../../assets/img/icon/Office_Icon.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="services-content">
                <h2 className="title">
                  <a >The Palm Business Hub</a>
                </h2>
                <h2 className="number">02</h2>
              </div>
              <div className="services-overlay-content">
                <h2 className="title">
                  <a>The Palm Business Hub</a>
                </h2>
                <p>
                  Set to be launched soon, The Palm Business Hub is our ambitious
                  commercial project that aims to revolutionize the business landscape.
                  Designed to be a state-of-the-art commercial hub, it will cater to the
                  diverse needs of businesses, providing them with the perfect environment
                  to thrive and grow.
                </p>
                <a href="/A3_Broucher-Palm-14th_June.pdf" download className="read-more">
                  Download Brochure <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-4 col-md-6 col-sm-10">
            <div
              className="services-item wow fadeInUp"
              data-wow-delay=".6s"
              style={{
                background: `url("../../assets/img/services/services_item03.jpg")`,
              }}
            >
              <div className="services-icon">
                <img
                  src={
                    require(`../../assets/img/icon/services_icon03.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="services-content">
                <h2 className="title">
                  <a href="/services/services-details">Roof Repairings</a>
                </h2>
                <h2 className="number">03</h2>
              </div>
              <div className="services-overlay-content">
                <h2 className="title">
                  <a href="/services/services-details">Roof Repairings</a>
                </h2>
                <p>
                  There are many variations of passages of Lorem a Ipsum
                  available, but the majority have suffered ali teration in some
                  form
                </p>
                <a href="/services/services-details" className="read-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div> */}
          {/* <div className="col-lg-4 col-md-6 col-sm-10">
            <div
              className="services-item wow fadeInUp"
              data-wow-delay=".3s"
              style={{
                background: `url("../../assets/img/services/services_item04.jpg")`,
              }}
            >
              <div className="services-icon">
                <img
                  src={
                    require(`../../assets/img/icon/services_icon04.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="services-content">
                <h2 className="title">
                  <a href="/services/services-details">Roof Renovation</a>
                </h2>
                <h2 className="number">04</h2>
              </div>
              <div className="services-overlay-content">
                <h2 className="title">
                  <a href="/services/services-details">Roof Renovation</a>
                </h2>
                <p>
                  There are many variations of passages of Lorem a Ipsum
                  available, but the majority have suffered ali teration in some
                  form
                </p>
                <a href="/services/services-details" className="read-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div> */}
          {/* <div className="col-lg-4 col-md-6 col-sm-10">
            <div
              className="services-item wow fadeInUp"
              data-wow-delay=".6s"
              style={{
                background: `url("../../assets/img/services/services_item05.jpg")`,
              }}
            >
              <div className="services-icon">
                <img
                  src={
                    require(`../../assets/img/icon/services_icon05.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="services-content">
                <h2 className="title">
                  <a href="/services/services-details">Damage Roofing</a>
                </h2>
                <h2 className="number">05</h2>
              </div>
              <div className="services-overlay-content">
                <h2 className="title">
                  <a href="/services/services-details">Damage Roofing</a>
                </h2>
                <p>
                  There are many variations of passages of Lorem a Ipsum
                  available, but the majority have suffered ali teration in some
                  form
                </p>
                <a href="/services/services-details" className="read-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div> */}
          {/* <div className="col-lg-4 col-md-6 col-sm-10">
            <div
              className="services-item wow fadeInUp"
              data-wow-delay=".9s"
              style={{
                background: `url("../../assets/img/services/services_item06.jpg")`,
              }}
            >
              <div className="services-icon">
                <img
                  src={
                    require(`../../assets/img/icon/services_icon06.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="services-content">
                <h2 className="title">
                  <a href="/services/services-details">Roofing Animation</a>
                </h2>
                <h2 className="number">06</h2>
              </div>
              <div className="services-overlay-content">
                <h2 className="title">
                  <a href="/services/services-details">Roofing Animation</a>
                </h2>
                <p>
                  There are many variations of passages of Lorem a Ipsum
                  available, but the majority have suffered ali teration in some
                  form
                </p>
                <a href="/services/services-details" className="read-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default ServiceArea;
