import React from "react";

function MainWid() {
  function validateNumberInput(event) {
    event.target.value = event.target.value.replace(/[^0-9]/g, '').slice(0, 10);
  }
  return (
    <section className="contact-area pt-120 pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-10">
            <div
              className="contact-form-wrap"
              style={{
                backgroundImage: `url(${require("../../assets/img/images/contact_form_bg.jpg")})`,
              }}
            >
              <h2 className="title">Book Your Dream Home</h2>
              <p>Our experts will respond to you soon</p>
              <form action="#" className="contact-form">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        id="firstName"
                        type="text"
                        placeholder="Name*"
                        required />
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        id="lastName"
                        type="text"
                        placeholder="Last Name*"
                      />
                    </div>
                  </div> */}
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        id="phone"
                        type="text"
                        maxLength="10"
                        placeholder="Phone Number*"
                        required
                        onInput={validateNumberInput}
                      />
                    </div>
                  </div>

                </div>
                <div className="">
                  <div className="form-grp">
                    <input
                      id="email"
                      type="email"
                      placeholder="Email Address*"
                    />
                  </div>
                </div>
                {/* <div className="form-grp">
                  <input id="subject" type="text" placeholder="Subject" />
                </div> */}
                <div className="form-grp">
                  <textarea
                    id="message"
                    placeholder="Your Message here"
                  ></textarea>
                </div>
                <button className="btn" type="submit">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-xl-6 col-lg-10">
            <div className="contact-info-wrap">
              <h2 className="title">Need Any Help?</h2>
              <p>Call us or message and we' ll respond as soon as possible</p>
              <ul className="list-wrap ">
                <div className="row ">
                  <div className="col-lg-4">
                    <li>
                      <div className="contact-info-item " >
                        <div className="icon">
                          <i className="fas fa-phone-alt"></i>
                        </div>
                        <div className="content">
                          {/* <a href="tel:0123456789">+(323) 9847 3847 383</a> */}
                          <a href="tel:+91 99099 59910">+91 99099 59910</a>
                        </div>
                      </div>
                    </li>
                    </div>
                  <div className="col-lg-4">
                  <li>
                    <div className="contact-info-item">
                      <div className="icon">
                        <i className="fas fa-envelope"></i>
                      </div>
                      <div className="content">
                        <a href="mailto:palmspringsrealities@gmail.com">palmspringsrealities<br/>@gmail.com</a>
                        {/* <a href="mailto:infoyour@gmail.com">
                        domaininfo@gmail.com
                      </a> */}
                      </div>
                    </div>
                  </li>
                  </div>
                  <div className="col-lg-4">
                  <li>
                    <div className="contact-info-item">
                      <div className="icon">
                        <i className="fas fa-map-marker-alt"></i>
                      </div>
                      <div className="content">
                        <p>
                          The Palm Paradise by The Palm Group, Nava Vadaj, Ahmedabad
                        </p>
                      </div>
                    </div>
                  </li>
                  </div>
                
                </div>

              </ul>
            </div>
            <div id="contact-map">
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d234974.56200800434!2d72.38826478133998!3d23.042948149198452!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e8341fc551eeb%3A0x55277f96958a08a1!2sThe%20Palm%20Paradise!5e0!3m2!1sen!2sin!4v1723036011131!5m2!1sen!2sin"
                allowFullScreen
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainWid;
