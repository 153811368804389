import React from "react";

function FooterOne() {
  return (
    <footer>
      <div
        className="footer-area footer-bg"
        style={{
          backgroundImage: `url(${require("../../../assets/img/bg/footer_bgfinal.jpg")})`,
        }}
      >
        <div className="footer-top">
          <div className="container ">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 ">
                <div className="footer-widget">
                  <h2 className="fw-title">About Us</h2>
                  <div className="footer-content">
                    <p>
                      Founded two decades ago by the visionary Suketu Shah,
                      The Palm Group has established itself as a prominent name
                      in the real estate industry. With a legacy of 20 years, we
                      have been at the forefront of developing properties that stand
                      as benchmarks of quality, innovation, and excellence.
                    </p>
                    <p>
                    Under the dynamic leadership of Rishil Shah, The Palm Group continues
                     to thrive, expanding its horizons and setting new standards in the 
                     real estate sector. Our commitment to creating exceptional residential
                      and commercial spaces has earned us the trust and respect of countless
                       clients and stakeholders.
                    </p>
                    {/* <div className="footer-newsletter">
                      <h4 className="title">Subscribe to Our Newsletter</h4>
                      <form action="#">
                        <input type="text" placeholder="Enter your email" />
                        <button type="submit" className="btn btn-two">
                          Subscribe
                        </button>
                      </form>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6">
                <div className="footer-widget">
                  <h2 className="fw-title">Our Services</h2>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li>
                        <a href="/project">
                          <i className="fas fa-angle-double-right"></i>Single
                          Ply Roofing
                        </a>
                      </li>
                      <li>
                        <a href="/project">
                          <i className="fas fa-angle-double-right"></i>Modified
                          Roofing
                        </a>
                      </li>
                      <li>
                        <a href="/project">
                          <i className="fas fa-angle-double-right"></i>Built-Up
                          Roofing
                        </a>
                      </li>
                      <li>
                        <a href="/project">
                          <i className="fas fa-angle-double-right"></i>Metal
                          Roofing
                        </a>
                      </li>
                      <li>
                        <a href="/project">
                          <i className="fas fa-angle-double-right"></i>
                          Vegetative Roofing
                        </a>
                      </li>
                      <li>
                        <a href="/project">
                          <i className="fas fa-angle-double-right"></i>Specialty
                          Metal
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                <div className="footer-widget">
                  <h2 className="fw-title">Quick Links</h2>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li>
                        <a href="/contact">
                          <i className="fas fa-angle-double-right"></i>Terms &
                          Conditions
                        </a>
                      </li>
                      <li>
                        <a href="/contact">
                          <i className="fas fa-angle-double-right"></i>Tax & Vat
                        </a>
                      </li>
                      <li>
                        <a href="/contact">
                          <i className="fas fa-angle-double-right"></i>Refund
                          Policy
                        </a>
                      </li>
                      <li>
                        <a href="/contact">
                          <i className="fas fa-angle-double-right"></i>Typically
                          Solutions
                        </a>
                      </li>
                      <li>
                        <a href="/contact">
                          <i className="fas fa-angle-double-right"></i>Our
                          Process
                        </a>
                      </li>
                      <li>
                        <a href="/contact">
                          <i className="fas fa-angle-double-right"></i>Contact
                          Us
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
              <div className="col-xl-6 col-lg-6 col-md-6 flex justify-center">
                <div className="footer-widget">
                  <h2 className="fw-title">Instagram Posts</h2>
                  <div className="footer-instagram">
                    <ul className="list-wrap">
                      <li>
                        <a href="https://www.instagram.com/thepalmspringrealty?" target="_blank" rel="noopener noreferrer">
                          <img
                            src={require(`../../../assets/img/instagram/img1.jpg`)}
                            alt=""
                          />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/thepalmspringrealty?" target="_blank" rel="noopener noreferrer">
                          <img
                            src={require(`../../../assets/img/instagram/img2.jpg`)}
                            alt=""
                          />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/thepalmspringrealty?" target="_blank" rel="noopener noreferrer">
                          <img
                            src={require(`../../../assets/img/instagram/img3.jpg`)}
                            alt=""
                          />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/thepalmspringrealty?" target="_blank" rel="noopener noreferrer">
                          <img
                            src={require(`../../../assets/img/instagram/img4.jpg`)}
                            alt=""
                          />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/thepalmspringrealty?" target="_blank" rel="noopener noreferrer">
                          <img
                            src={require(`../../../assets/img/instagram/img5.jpg`)}
                            alt=""
                          />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/thepalmspringrealty?" target="_blank" rel="noopener noreferrer">
                          <img
                            src={require(`../../../assets/img/instagram/img6.jpg`)}
                            alt=""
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-logo-area">
              <div className="row align-items-center">
                <div className="col-xl-4 col-lg-3 col-md-12">
                  <div className="logo">
                    <a href="/">
                      <img
                        src={require(`../../../assets/img/logo/The Palm Group (W) 2-01.png`)}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="footer-contact">
                    <div className="icon">
                      <i className="fas fa-phone-alt"></i>
                    </div>
                    <div className="content">
                      <span>Need Any Help</span>
                      <a href="tel:+91 99099 59910">+91 99099 59910</a>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-5 col-md-6">
                  <div className="footer-social">
                    <h2 className="title">Follow Us:</h2>
                    <ul className="list-wrap">
                      <li>
                        <a href="https://www.facebook.com/profile.php?id=100082949361907" target="_blank" rel="noopener noreferrer">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/thepalmspringrealty?" target="_blank" rel="noopener noreferrer">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      {/* <li>
                        <a href="#">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="copyright-text">
                  <p>Copyright: PALMSPRINGS REALITIES LLP</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="footer-bootom-menu">
                  <ul className="list-wrap">
                    <li>
                      
                      <a href="/privacypolicy">Privacy Policy</a>
                    </li>
                    <li>
                    <span className="span1">Designed By :</span>
                    <a href="https://www.instagram.com/abigcommunication?" target="_blank" rel="noopener noreferrer"> A Big Communication</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterOne;
